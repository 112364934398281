import React from 'react'

const Projects = () => {
  return (
    <div className="flex flex-col md:flex-row">
    <section className="flex flex-col gap-5 md:gap-10 w-full p-5 md:px-24">
      <h3 className="w-fit text-green-800 mx-auto text-4xl md:text-6xl p-5 font-times font-bold text-center">
        Why Choose Our Project in Ranikhet?
      </h3>
      <ol className="flex flex-col w-fit md:w-3/4 justify-center mx-auto gap-3 md:gap-5  text-lg font-times md:text-2xl">
        <li className="flex animate__zoomIn3 bg-green-50 p-2 rounded-xl gap-5 hover:bg-green-200 hover:scale-105 duration-500 ">
          <span className="text-black"> &#10146;</span>Serene and tranquil
          environment
        </li>
        <li className="flex bg-green-50 p-2 rounded-xl gap-5 md:hover:bg-green-200 hover:scale-105 duration-500 ">
          <span className="text-black"> &#10146;</span>Stunning views of the
          Himalayas
        </li>
        <li className="flex bg-green-50 p-2 rounded-xl gap-5 md:hover:bg-green-200 hover:scale-105 duration-500 ">
          <span className="text-black"> &#10146;</span> Modern amenities and
          facilities
        </li>

        <li className="flex bg-green-50 p-2 rounded-xl gap-5 md:hover:bg-green-200 hover:scale-105 duration-500 ">
          <span className="text-black"> &#10146;</span> Flexible payment plans
        </li>

        <li className="flex bg-green-50 p-2 rounded-xl gap-5 md:hover:bg-green-200 hover:scale-105 duration-500 ">
          <span className="text-black"> &#10146;</span> Convenient location
        </li>

        <li className="flex bg-green-50 p-2 rounded-xl gap-5 md:hover:bg-green-200 hover:scale-105 duration-500 ">
          <span className="text-black"> &#10146;</span>Expertly crafted homes
          that cater to your needs and preferences
        </li>
      </ol>

      <p className="text-xl md:text-3xl  px-3 font-serif text-justify">
        Our project in
        <span className="text-green-800"> Ranikhet </span>is more than just a
        residential development; it is a lifestyle choice. Here are some
        reasons why you should choose our project:
      </p>

      <ol className="flex flex-col  gap-1 md:gap-5 w-full  text-lg font-times md:text-2xl md:text-justify">
        <li className="flex animate__zoomIn3 bg-green-50 p-2 rounded-xl gap-1 md:gap-5 md:hover:bg-green-200 hover:scale-95 duration-500  ">
          <span className="text-black"> &#10146;</span>
          <div className="flex flex-col">
            <h4 className="text-2xl font-semibold text-green-800 font-times">
              Natural Beauty:
            </h4>
            <p className="text-lg md:text-2xl font-serif text-justify">
              Ranikhet is a paradise for nature enthusiasts. The town is
              surrounded by dense pine forests, rolling hills, and majestic
              mountain ranges. The panoramic views of the Himalayas, with
              peaks like Nanda Devi and Trishul standing tall, are a sight to
              behold. The lush greenery, pristine air, and peaceful atmosphere
              make it an ideal destination for those seeking solace in
              nature's embrace.
            </p>
          </div>
        </li>
        <li className="flex bg-green-50 p-2 rounded-xl gap-1 md:gap-5 md:hover:bg-green-200 hover:scale-95 duration-500  ">
          <span className="text-black"> &#10146;</span>
          <div className="flex flex-col">
            <h4 className="text-2xl font-semibold text-green-800 font-times">
              Cultural Heritage:
            </h4>
            <p className="text-lg md:text-2xl font-serif text-justify">
              Ranikhet is steeped in history and culture. It was established
              as a British hill station during the colonial era and still
              retains its colonial charm. The town is dotted with colonial-era
              buildings, churches, and bungalows that reflect its rich
              heritage. The Kumaon Regimental Centre Museum, showcasing the
              history of the Indian Army, is a must-visit for history buffs.
              Ranikhet is also known for its temples, including the Jhula Devi
              Temple and the Haidakhan Temple, which are revered by locals and
              visitors alike.
            </p>
          </div>
        </li>
        <li className="flex  bg-green-50 p-2 rounded-xl gap-1 md:gap-5 md:hover:bg-green-200 hover:scale-95 duration-500 ">
          <span className="text-black"> &#10146;</span>
          <div className="flex flex-col">
            <h4 className="text-2xl font-semibold text-green-800 font-times">
              Modern Comforts:
            </h4>
            <p className="text-lg md:text-2xl font-serif text-justify">
              Despite its small size, Ranikhet offers modern amenities to
              ensure a comfortable stay for visitors. There are several
              hotels, resorts, and homestays that cater to different budgets
              and preferences. The town has a bustling market where you can
              shop for local handicrafts, woolens, and souvenirs. There are
              also plenty of restaurants serving delicious Kumaoni cuisine as
              well as Indian and international dishes.
            </p>
          </div>
        </li>
        <li className="flex bg-green-50 p-2 rounded-xl gap-1 md:gap-5 md:hover:bg-green-200 hover:scale-95 duration-500  ">
          <span className="text-black"> &#10146;</span>

          <div className="flex flex-col">
            <h4 className="text-2xl font-semibold text-green-800 font-times">
              Recreational Activities:
            </h4>
            <p className="text-lg md:text-2xl font-serif text-justify">
              Ranikhet offers a range of recreational activities for visitors.
              You can go for leisurely walks or trekking in the surrounding
              forests and hills. Golf enthusiasts can enjoy a round of golf at
              the beautiful Ranikhet Golf Course, which is one of the highest
              golf courses in Asia. Birdwatchers will be delighted by the
              diverse avian species found in the region. Adventure seekers can
              indulge in activities like paragliding, rappelling, and rock
              climbing.
            </p>
          </div>
          </li>
          <li className="flex bg-green-50 p-2 rounded-xl gap-1 md:gap-5 md:hover:bg-green-200 hover:scale-95 duration-500  ">
          <span className="text-black"> &#10146;</span>
          <div className="flex flex-col">
            <h4 className="text-2xl font-semibold text-green-800 font-times">
              Accessibility:
            </h4>
            <p className="text-lg md:text-2xl font-serif text-justify">
              Ranikhet is well-connected by road and rail. The nearest railway
              station is Kathgodam, which is about 80 kilometers away. The
              nearest airport is in Pantnagar, which is about 110 kilometers
              away. From there, you can hire a taxi or take a bus to reach
              Ranikhet. The town is also well-connected to major cities like
              Delhi, Nainital, and Almora by road.
            </p>
          </div>
        </li>
      </ol>
    </section>
  </div>
  )
}

export default Projects
