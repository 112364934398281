import React from 'react'
import img1 from '../assets/photos/about_page/img1.jpeg'
import WebsiteLinks from './WebsiteLinks';
import map from "../assets/photos/map.jpeg"


const Introduction = () => {
    const handleButtonClick1 = () => {
        window.open("/", "_blank");
      };
    

  return (
    <div className="flex flex-col gap-5 md:gap-0">
    <img className="w-full" src={img1} alt="img1" />
    <h1 className="text-4xl md:text-6xl font-bold font-times md:pt-10 text-green-800 text-center bg-cover">
      Thankyou for showing interest in our project
    </h1>
    <div className="flex flex-col gap-5 border-4 md:p-3  md:m-14  shadow-lg shadow-green-300 md:hover:shadow-green-200 bg-green-50">
      <p className="text-lg md:text-2xl md:mx-8 px-3 font-serif text-justify">
        <span className="text-green-800"> Welcome to Ranikhet</span>, a
        tranquil town nestled in the Kumaon region of the Indian Himalayas.
        Its name, which translates to "Queen's Meadow," is a testament to the
        breathtaking beauty that has attracted travelers, artists, and nature
        lovers for generations. A place where time seems to stand still,
        Ranikhet offers a perfect blend of natural splendor, cultural
        heritage, and modern comforts.
      </p>
    </div>

    <div
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      data-vc-stretch-content="true"
      className="contact_map row-fluid no-padd margin-lg-115t margin-sm-50t no-padd relative box-border"
    >
      <div className="google-maps mb-10 w-full">
        <h3 className="w-fit text-green-800 mx-auto text-4xl md:text-6xl p-5 font-times font-bold text-center">
          Google Location
        </h3>
        <div className="flex flex-col items-center mx-auto gap-5">
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11382.509142864193!2d79.41864612945095!3d29.64801833407351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f82a7c8f9e5c7%3A0x2d67a9e8a7d0b4b7!2sRanikhet%2C%20Uttarakhand!5e0!3m2!1sen!2sin!4v1631782473884!5m2!1sen!2sin"
            className="w-full p-3 h-96 border-0"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe> */}
          <a
    href="https://maps.google.com?q=29.6600591,79.5256596&hl=en-IN&gl=in&entry=gps&lucs=,47071704,47084304,94206605&g_st=iw"
    target="_blank"
    rel="noopener noreferrer"
    
  >
    <img className="w-full p-5 md:p-10 border-0" src={map} alt="map location" target="_blank" />
  </a>
        </div>
      </div>
    </div>

    <h3 className="w-fit text-green-800 mx-auto text-4xl md:text-6xl p-5 font-times font-bold text-center">
      3D Walkthrough
    </h3>

    <video
      src="/bg_video/bg2_video.mp4" 
      className="w-full md:h-[700px]  p-3 md:p-16 object-cover"
      autoPlay
      loop
      muted
      playsInline
    />

    <div>
      <WebsiteLinks/>

    </div>

    <div className="flex flex-col p-5 md:m-16 md:mt-0 gap-5 bg-green-50 md:hover:bg-green-100">
      <section className="flex flex-col md:flex-row gap-3 md:gap-5 items-center">
        <p className="text-lg md:text-3xl font-semibold md:mr-16 px-3 font-serif text-justify">
          For more information, we request you to visit our website
        </p>

        <button
          onClick={handleButtonClick1}
          className="bg-green-600 text-white font-semibold text-sm md:text-xl md:mx-auto  font-serif p-3 md:p-4 rounded-xl hover:bg-green-200 hover:border-green-600 hover:border-2 hover:text-black hover:font-bold focus:outline-none"
        >
          CLICK HERE
        </button>
      </section>
    </div>
  </div>
  )
}

export default Introduction
