import React from "react";
import Banner from "./Banner";
import AboutUs from './AboutUs';
import Projects from './Projects';
import Gallery from './Gallery';
import Contact from "./Contact";
import GoogleMap from "./GoogleMap";
import Layout3D from "./Layout3D";

function Home() {
  return (
    <div>
     <Banner/>
    <AboutUs/>
    <GoogleMap/>
    <Layout3D/>
    <Projects/>
    <Gallery/>
    <Contact/>
    </div>
  );
}

export default Home;
