// Import necessary dependencies
import React from "react";
import bgContact from "../assets/bg_img/contact_bg2.jpg";
// import ContactForm from "./ContactForm";
// import CompanyDetail from "./CompanyDetail";
import ContactAddress from "./ContactAddress";

// Define the functional component for the form
const Contact = () => {
  return (
    <>
      {/* <CompanyDetail/> */}
      <div style={{ backgroundImage: `url(${bgContact})`}} className="bg-cover flex flex-col justify-center items-center mx-auto bg-center md:p-10">
      <h2 className="animate__zoomIn text-2xl md:text-5xl text-center text-white font-serif pt-14 md:pt-10">
      We are here to help you get your Dream Land!
      </h2>
      {/* <p className="text-lg md:text-2xl text-white font-mono my-2 mx-5 text-center">
              We are here to help you get your Dream Land!
            </p> */}
        <ContactAddress/>
       
        {/* <ContactForm/> */}
      </div>      
    </>
  );
};

// Export the component
export default Contact;
