import React, { useState } from "react";
import bg2 from "../assets/photos/gallery_img/bg2.jpg";
import gal_img1 from "../assets/photos/gallery_img/gal_img1.webp";
import gal_img2 from "../assets/photos/gallery_img/gal_img2.webp";
import gal_img3 from "../assets/photos/gallery_img/gal_img3.webp";
import gal_img4 from "../assets/photos/gallery_img/gal_img4.webp";
import gal_img5 from "../assets/photos/gallery_img/gal_img5.webp";
import gal_img6 from "../assets/photos/gallery_img/gal_img6.webp";
import gal_img7 from "../assets/photos/gallery_img/gal_img7.webp";
import gal_img8 from "../assets/photos/gallery_img/gal_img8.webp";
import gal_img9 from "../assets/photos/gallery_img/gal_img9.webp";
const images = [
  gal_img1,
  gal_img2,
  gal_img3,
  gal_img4,
  gal_img5,
  gal_img6,
  gal_img7,
  gal_img8,
  gal_img9,

  // ... add more paths as needed
];

function Gallery() {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  // Back button handler
  const goBackToGallery = () => {
    setFullscreenImage(null);
  };

  // Next button handler
  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    console.log("Next Index:", nextIndex);
    console.log("Next Image:", images[nextIndex]);

    setCurrentIndex(nextIndex);
    openFullscreen(images[nextIndex], nextIndex);
  };

  return (
    <>
      <h2 className="text-4xl md:text-6xl w-fit mx-auto md:py-10  hover:underline font-times font-bold text-center p-5">
        GALLERY
      </h2>
      <div
        style={{ backgroundImage: `url(${bg2})` }}
        className="grid grid-cols-1 md:grid-cols-3 gap-10 p-5 md:p-24 md:pt-36 bg-cover mx-auto"
      >
        {images.map((image, index) => (
          <img
            key={index}
            className="shadow-md shadow-amber-50 gap-5 rounded-xl md:hover:scale-110 md:duration-700 cursor-pointer"
            src={image}
            alt={`Gallery Img ${index + 1}`}
            onClick={() => openFullscreen(image)}
          />
        ))}
      </div>

      {fullscreenImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center">
          <button
            className="absolute bottom-28 left-20 text-Black bg-white font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none
            "
            onClick={goBackToGallery}
          >
            Back
          </button>

          <button
            className="absolute bottom-28 right-20 text-Black bg-white font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            onClick={showNextImage}
          >
            Next
          </button>
          <img
            src={fullscreenImage}
            alt="Fullscreen Img"
            className="max-h-full max-w-full cursor-pointer md:p-20 md:pt-32"
            onClick={closeFullscreen}
          />
        </div>
      )}
    </>
  );
}

export default Gallery;
