import React from 'react'
// import location from "./icons2/location.gif";
// import TSGOC_LOGO_F_8 from "../assets/icons/TSGOC_LOGO_F_8.png"
import message from "../assets/icons/message.gif";
import phone from "../assets/icons/phone.gif";


import icon_facebook from "../assets/icons/icon_facebook.png";
import icon_twitter from "../assets/icons/icon_twitter.png";
import icon_insta from "../assets/icons/icon_insta.png";
import icon_linkdin from "../assets/icons/icon_linkdin.png";

function ContactAddress() {
  const phoneNumber = "8510850101";
  return (
    <section className='w-full flex flex-col md:flex-row md:m-3 p-5 md:backdrop:pt-14 rounded-xl md:gap-10 md:mx-40 bg-black bg-opacity-40'>
      <div className="flex w-fit md:w-2/3 md:py-20 md:px-20 md:p-14 flex-col gap-6">
     
          <div className="flex flex-col gap-3">
            <div>
              {/* <a href="/">
              <img className='w-48' src={logo} alt='logo'/>
              </a> */}
             
            </div>

            <div className="flex gap-3 py-3 md:py-5 px-5 md:px-0 items-center">
              <a href="mailto:info@theshubhamgoc.com">
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={message}
                  alt="message"
                />
              </a>
              <a
                className="text-base md:text-xl text-white w-fit pl-0 md:p-3 font-semibold hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                {"info@tsgoc.in "}
                
              </a>
            </div>

            <div className="flex gap-3 py-3 px-5 md:py-5 md:px-0 items-center">
              <a href={`tel:${phoneNumber}`}>
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={phone}
                  alt="message"
                />
              </a>
              <p className="text-base md:text-xl text-white w-fit pl-0 md:p-3"> <span className="text-yellow-400">8510</span> 850 101</p>
              {/* <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> */}
            </div>
          </div>

          <div className="flex flex-col ">
            <p className="text-2xl text-white w-fit md:text-3xl py-3 md:py-5 px-5 md:px-0 font-serif">Connect with us:</p>

            <div className="flex w-fit md:gap-8 px-5 md:px-0 gap-3 py-3">
              <a
                href="https://www.facebook.com/IndusValleyMukteshwar/"
                target="_blank"
              >
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={icon_facebook}
                  alt="Facebook Link.."
                />
              </a>

              {/* <a href="https://twitter.com/theshubhamgroup" target="_blank">
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={icon_twitter}
                  alt="Twitter Link.."
                />
              </a> */}

              {/* <a
                href="https://www.instagram.com/theshubhamgroup/"
                target="_blank"
              >
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={icon_insta}
                  alt="Insta Link.."
                />
              </a> */}

              {/* <a
                href="https://www.linkedin.com/company/shubham-group-of-companies/"
                target="_blank"
              >
                <img
                  className="w-10 bg-white rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={icon_linkdin}
                  alt="Linkdin Link.."
                />
              </a> */}
            </div>
          </div>
      </div>
      <iframe className="text-3xl md:text-4xl h-[450px] md:h-[600px]  md:w-1/4 py-10 md:py-32 md:px-20 text-white rounded-2xl font-semibold font-serif"
        id="Contact_Form"
        src="https://account.solidperformers.com/capture_form_data/OTM3"
        border="0"
        style={{ border: 0, width: "100%" }}
      ></iframe>
        </section>
  )
}

export default ContactAddress
