import React from 'react'
import img1 from '../assets/photos/about_page/img1.jpeg'

const AboutUs = () => {
  return (
    <div className="flex flex-col gap-5">
    <div className="flex flex-col gap-3 md:gap-10 md:px-20 p-5 pb-0">
      <h1 className="text-4xl md:text-6xl font-bold font-times md:pt-10 text-green-800 text-center bg-cover">
        A Timeless Treasure in the Himalayas
      </h1>
     
      <div className="flex flex-col justify-center">
        <img className="mx-auto md:h-96 w-full" src={img1} alt="img1" />

        <p className="text-lg md:text-2xl px-3 md:px-28 p-5 md:hover:bg-green-100 md:p-10 font-serif text-justify">
          Our project in Ranikhet is a meticulously planned residential
          development that aims to offer a perfect blend of modern living and
          natural beauty. The project is spread across acres of pristine land
          and offers a range of residential options to suit your lifestyle and
          preferences. Whether you are looking for a cozy apartment or a
          spacious villa, our project has something for everyone.
        </p>
      </div>
    </div>
  </div>
  )
}

export default AboutUs
