import React from "react";
// import arrow from "../Assets/icons/arrow.png";

function WebsiteLinks() {
  const handleButtonClick1 = () => {
    window.open("https://www.tsgoc.in/", "_blank");
  };

  const handleButtonClick2 = () => {
    window.open("/projects", "_blank");
  };

  const handleButtonClick3 = () => {
    window.open("/gallery", "_blank");
  };

// const handleButtonClick4 = () => {
//     window.open("/nearby", "_blank");
//   };

  return (
    <div className="flex flex-col gap-3 md:gap-0">
      <div>
        <h3 className="w-fit mx-auto text-3xl md:text-6xl md:py-10 hover:underline font-times font-bold text-center p-5">
          STEPS TO EASY ACCESS OUR WEBSITE
        </h3>
      </div>

      <section className="flex flex-col gap-5 border-4 md:py-10 md:m-14 md:mt-0 shadow-lg shadow-green-200 bg-green-50">
        <div className="flex items-center px-3 gap-3 md:gap-5 justify-between">
          <div className="w-1/2 md:w-fit md:mx-16 gap-0 md:gap-2 flex flex-col md:flex-row">
            <p className=" text-xl md:text-3xl font-serif font-semibold md:text-justify">
              Know
            </p>
            <p className="font-semibold text-xl md:text-3xl font-serif text-[#84B951]">
              About Us
            </p>
          </div>
          <button
            onClick={handleButtonClick1}
            className="w-1/3 md:w-fit bg-green-600 text-white  font-semibold text-sm md:text-xl md:mx-auto font-serif m-3 p-3 md:p-4 my-auto md:mr-8 px-8 rounded-xl hover:bg-green-200 hover:border-green-600 hover:border-2 hover:text-black hover:font-bold focus:outline-none"
          >
            CLICK HERE
          </button>
        </div>

        <div className="flex  items-center px-3 gap-3 md:gap-5 justify-between">
          <div className="w-1/2 md:w-fit md:mx-16 gap-0 md:gap-2 flex flex-col md:flex-row">
            <p className="font-semibold text-xl md:text-3xl font-serif md:text-justify">
              For
            </p>
            <p className="font-semibold text-xl md:text-3xl font-serif text-[#84B951]">
              
              Project Details
            </p>
          </div>

          <button
            onClick={handleButtonClick2}
            className="w-1/3 md:w-fit bg-green-600 text-white  font-semibold text-sm md:text-xl md:mx-auto font-serif m-3 p-3 md:p-4 my-auto md:mr-8 px-8 rounded-xl hover:bg-green-200 hover:border-green-600 hover:border-2 hover:text-black hover:font-bold focus:outline-none"
          >
            CLICK HERE
          </button>
        </div>

        <div className="flex items-center px-3 gap-3 md:gap-5 justify-between">
          <div className="w-1/2 md:w-fit md:mx-16 gap-0 md:gap-2 flex flex-col md:flex-row">
            <p className="font-semibold text-xl md:text-3xl font-serif md:text-justify">
              For
            </p>
            <p className="font-semibold text-xl md:text-3xl font-serif text-[#84B951]">
    
              Photos / Gallery
            </p>
          </div>

          <button
            onClick={handleButtonClick3}
            className="w-1/3 md:w-fit bg-green-600 text-white  font-semibold text-sm md:text-xl md:mx-auto font-serif m-3 p-3 md:p-4 my-auto md:mr-8 px-8 rounded-xl hover:bg-green-200 hover:border-green-600 hover:border-2 hover:text-black hover:font-bold focus:outline-none"
          >
            CLICK HERE
          </button>
        </div>

        {/* <div className="flex items-center px-3 md:gap-5 justify-between">
          <div className="w-1/2 md:w-fit md:mx-16 gap-0 md:gap-2 flex flex-col md:flex-row">
            <p className="font-semibold text-xl md:text-3xl font-serif md:text-justify">
              For{" "}
            </p>
            <p className="font-semibold text-xl md:text-3xl font-serif text-[#84B951]">
              {" "}
              Nearby Locations
            </p>
          </div>

          <button
            onClick={handleButtonClick4}
            className="w-1/3 md:w-fit bg-green-600 text-white  font-semibold text-sm md:text-xl md:mx-auto font-serif m-3 p-3 md:p-4 my-auto md:mr-8 px-8 rounded-xl hover:bg-green-200 hover:border-green-600 hover:border-2 hover:text-black hover:font-bold focus:outline-none"
          >
            CLICK HERE
          </button>
        </div> */}
      </section>
    </div>
  );
}

export default WebsiteLinks;
