import React from "react";
import map from "../assets/photos/map.jpeg"
function GoogleMap({ google_map }) {
  return (
    <div className="margin-lg-115t margin-sm-50t no-padd">
      <div
        data-vc-full-width="true"
        data-vc-full-width-init="true"
        data-vc-stretch-content="true"
        className="contact_map row-fluid no-padd margin-lg-115t margin-sm-50t no-padd relative box-border"
      >
        <div className="google-maps mb-10 w-full">
          <h3 className="w-fit text-green-800 mx-auto text-4xl md:text-6xl p-5 font-times font-bold text-center">
            Google Map
          </h3>
          <div className="flex flex-col items-center md:flex-row md:px-28 md:pt-5 gap-5">
         
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11382.509142864193!2d79.41864612945095!3d29.64801833407351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f82a7c8f9e5c7%3A0x2d67a9e8a7d0b4b7!2sRanikhet%2C%20Uttarakhand!5e0!3m2!1sen!2sin!4v1631782473884!5m2!1sen!2sin"
              className="w-full md:w-1/2 border-0"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            ></iframe> */}

  <a
    href="https://maps.google.com?q=29.6600591,79.5256596&hl=en-IN&gl=in&entry=gps&lucs=,47071704,47084304,94206605&g_st=iw"
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-500 hover:underline"
  >
    <img className="w-full md:p-10 border-0" src={map} alt="map location" target="_blank" />
  </a>
           
            <p className="w-fit md:w-1/2 text-lg md:text-2xl p-7 pt-3 md:p-8 font-serif md:hover:bg-green-100 text-justify">
              Our project is strategically located in Ranikhet, offering easy
              access to major landmarks and amenities. It is situated close to
              the town center, making it convenient for residents to access
              shopping centers, restaurants, schools, and hospitals. The project
              is also surrounded by lush greenery and offers stunning views of
              the Himalayas, providing a serene and peaceful environment for
              residents
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleMap;
