import React from "react";

function Layout3D({ google_map }) {
  return (
    <div>
      <h3 className="w-fit text-green-800 mx-auto text-4xl md:text-6xl p-5 font-times font-bold text-center">
        3D Layout
      </h3>
      <div className="flex flex-col md:px-28 md:pt-5 gap-5">

      <video
      src="/bg_video/bg2_video.mp4" 
      className="w-full md:h-[600px]  p-3 md:p-0 object-cover"
      autoPlay
      loop
      muted
      playsInline
    />
        <div className="w-fit  text-lg md:text-2xl flex flex-col gap-3 md:gap-5 p-7 pt-3 md:p-8 md:py-0 font-serif md:hover:bg-green-100 text-justify">
          <p>
            The site's topography is characterized by rolling hills, lush
            forests, roads and commanding views of the surrounding mountains..
            This section presents an overview of the site layout, design
            considerations, and proposed strategies to enhance the overall
            experience for residents and visitors.
          </p>
          <p>
            The site's topography is characterized by rolling hills, lush
            forests, roads and commanding views of the surrounding mountains..
            This section presents an overview of the site layout, design
            considerations, and proposed strategies to enhance the overall
            experience for residents and visitors.
          </p>
          <p>
            The site's topography is characterized by rolling hills, lush
            forests, roads and commanding views of the surrounding mountains..
            This section presents an overview of the site layout, design
            considerations, and proposed strategies to enhance the overall
            experience for residents and visitors.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Layout3D;
