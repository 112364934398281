import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import Ivm_Resort from '../assets/logos/logo1.png';
import whatsapp from "../assets/icons/whatsapp.png"


function Header() {
    const [toggle, setToggle] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
         <header className={`bg-black brightness-100 backdrop-brightness-50 w-full z-10  sticky top-0
            ${scrolling ?'bg-transparent': 'bg-opacity-50'}`}>
            <div className='w-full flex flex-row justify-between px-5 items-center'>
                <div className='text-3xl font-bold '>
           <Link onClick={()=>{setToggle(!toggle)}} to='/'><img className='w-36 md:w-40' src={Ivm_Resort} alt='logo'/>
           </Link>  
             
                </div>

            {
                toggle ? 
                <AiOutlineClose onClick={()=>setToggle(!toggle)} className='text-white md:hidden block text-2xl mx-4'/> 
                : 
                <AiOutlineMenu onClick={()=>setToggle(!toggle)} className='text-white md:hidden block text-2xl mx-4'/>
            }

        <div className='hidden md:flex flex-row text-white text-xl px-4 gap-16'>
            
            <Link className='animate__zoomIn2 hover:text-[#69D69C] font-serif ' to='/'>Home</Link>
            <Link className='animate__zoomIn2 hover:text-[#69D69C] font-serif' to='https://www.tsgoc.in/' target="_blank"  >Company Profile</Link>
            <Link className='animate__zoomIn2 hover:text-[#69D69C] font-serif' to='/projects'>Projects</Link>
            <Link className='animate__zoomIn2 hover:text-[#69D69C] font-serif' to='/gallery'>Gallery</Link>
            {/* <Link className='animate__zoomIn2 hover:text-[#69D69C] font-serif' to='/nearby'>Nearby Sites</Link> */}
            <Link className='animate__zoomIn2 hover:text-[#69D69C] font-serif mr-10' to='/contact'>Contact Us</Link>
        </div>

        {/* {Below Code for responsive Menu} */}

        <div className={`md:hidden flex flex-col text-white fixed bg-black top-[88px] w-full h-screen
            ${toggle ? 'left-0' : 'left-[-100%]'} duration-1000`}>
  
            <Link onClick={()=>{setToggle(!toggle)}} to='/' className='p-5'>Home</Link>
             <Link onClick={()=>{setToggle(!toggle)}} to='https://www.tsgoc.in/' target="_blank"  className='p-5'>Company Profile</Link>
            <Link onClick={()=>{setToggle(!toggle)}} to='/projects' className='p-5'>Projects</Link>
            <Link onClick={()=>{setToggle(!toggle)}} to='/gallery' className='p-5'>Gallery</Link>
            {/* <Link onClick={()=>{setToggle(!toggle)}} to='/nearby' className='p-5'>Nearby Sites</Link> */}
            <Link onClick={()=>{setToggle(!toggle)}} to='/contact' className='p-5'>Contact</Link>
         </div>
        </div>
         
    </header>
      {/* WhatsApp Button */}
      <div style={{ position: 'fixed', bottom: '20px', left: '20px',zIndex:30 }}>
        <a
         href="https://wa.me/918744989989" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img 
            src={whatsapp} 
            alt="WhatsApp" 
            style={{ width: '50px', height: '50px', borderRadius: '50%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
          />
        </a>
      </div>
        </>
       
  )
}

export default Header

