import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/screens/Header';
import Home from './components/screens/Home';
import AboutUs from './components/screens/AboutUs';
import Projects from './components/screens/Projects';
import Gallery from './components/screens/Gallery';
import Contact from './components/screens/Contact';
import Error from './components/screens/Error';
import Footer from './components/screens/Footer';
import Introduction from './components/screens/Introduction';

function App() {
  return (
    <BrowserRouter>
    <Header/>
 <Routes>
    <Route exact path='/' element={<Home/>} /> 
    <Route exact path='/about' element={<AboutUs/>} /> 
    <Route exact path='/projects' element={<Projects/>}/> 
    <Route exact path='/gallery' element={<Gallery/>} />  
    <Route exact path='/contact' element={<Contact/>} /> 
    <Route exact path='/introduction' element={<Introduction/>} />
    <Route exact path="*" element={<Error/>}/>
 </Routes>
 <Footer/>
</BrowserRouter>
  );
}

export default App;
