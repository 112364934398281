import React from "react";
import brand_logo from "../assets/logos/TSGOC_LOGO_F_9.png";
import footer3 from "../assets/bg_img/footer5.png";

const Footer = () => {

  const phoneNumber = "8510850101";

  return (
    <footer
      style={{ backgroundImage: `url(${footer3})` }}
      className=" bg-cover py-8"
    >
      <div className="container mx-auto flex flex-col md:flex-row  justify-between m-5 p-5 gap-10">
        <section className="flex flex-col md:w-1/4 w-full gap-5">
          <p className="text-white text-2xl md:text-3xl font-semibold">
            Registered Office
          </p>
          <p className="text-white text-base font-times font-thin hover:underline">
            The Shubham Group, A-24, Okhla Industrial Area, Phase-2, Delhi.
            Pincode - 110020
          </p>
        </section>

        <section className="flex flex-col w-full md:w-1/4 gap-5">
          <p className="text-white text-2xl md:text-3xl font-semibold">
            Corporate Office
          </p>
          <p className="text-white text-base font-times font-thin hover:underline ">
            315, Block-B, Sector-105, Noida, Uttar Pradesh Pincode - 201304
          </p>
        </section>

        <section className="flex flex-col w-full md:w-1/4 md:px-10 gap-5">
          <p className="text-white text-2xl md:text-3xl font-semibold">
            Contact Us
          </p>
          <div className="flex flex-col">
            <a
              className="text-white text-base font-semibold hover:underline hover:text-blue-400 "
              href="mailto:info@tsgoc.in"
            >
              info@tsgoc.in
            </a>
            {/* <p className="text-white text-base font-semibold">
              <span className="text-yellow-400">8744</span> 989 989
            </p> */}
             <a
                  className="text-white hover:text-yellow-300 hover:underline"
                  href={`tel:${phoneNumber}`}
                >
                  <span className="text-yellow-400">8510 </span>850 101
                </a>
          </div>
        </section>

        <section className="w-full md:w-1/4">
          <a href="https://www.tsgoc.in" target="_blank" rel="noreferrer">
            <img
              className=" w-60 mx-auto rounded-lg hover:shadow-lg hover:shadow-slate-300 animate__zoomIn"
              src={brand_logo}
              alt="Brand Logo.."
            />
          </a>
        </section>
      </div>
      <section class="copy-right">
        <p className="text-white text-center text-sm font-times ">
          Copyright © 2009 The Shubham Group of Companies{" "}
        </p>
        <p className="text-white text-center text-sm font-times ">
          All rights reserved.
        </p>
      </section>
    </footer>
  );
};

export default Footer;
